import PropTypes from "prop-types";
import Button from "./Button";
import styled from "styled-components";

const Header = ({ title, backgroundColor, textColor }) => {
  const onClick = () => {
    console.log("click");
  };
  return (
    <StyledHeader style={{ backgroundColor: backgroundColor }}>
      <HeaderContainer>
        <TitleContainer>
          <h1 style={{ color: textColor }}>{title}</h1>
        </TitleContainer>
        <ButtonContainer>
          <Button color="green" text="Home" onClick={onClick}></Button>
          <Button color="yellow" text="Archive" onClick={onClick}></Button>
        </ButtonContainer>
      </HeaderContainer>
    </StyledHeader>
  );
};

Header.defaultProps = {
  title: "Anifan",
  backgroundColor: "black",
  textColor: "blue",
};

Button.propTypes = {
  title: PropTypes.string,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
};

const StyledHeader = styled.header`
  height: 80px;
  width: 100vw;
  margin: 0px;
  display: flex;
  justify-content: center;
`;

const HeaderContainer = styled.div`
  height: 100%;
  width: 80vw;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
`;

const TitleContainer = styled.div`
  margin-left: 10px;
  display: flex;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-right: 10 px;
  gap: 20px;
`;

export default Header;

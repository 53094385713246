import PropTypes from "prop-types";
import Button from "./Button";
import styled from "styled-components";

const StyledBody = styled.div`
  background-color: darkslateblue;
  width: 100vw;
  height: calc(100vh - 80px);
`;

const ContentWrapper = () => {
  return <StyledBody></StyledBody>;
};

export default ContentWrapper;

import Header from "../components/Header";
import ContentWrapper from "../components/ContentWrapper";

const Home = () => {
  return (
    <>
      <Header />
      <ContentWrapper />
    </>
  );
};

export default Home;

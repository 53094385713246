import PropTypes from "prop-types";
import styled from "styled-components";

const Button = ({ color, text, onClick }) => {
  return (
    <StyledButton
      onClick={onClick}
      style={{ backgroundColor: color }}
      className="btn"
    >
      {text}
    </StyledButton>
  );
};

Button.defaultProps = {
  color: "black",
  text: "Click",
};

Button.propTypes = {
  text: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func,
};

const StyledButton = styled.button`
  border: 0px;
  border-radius: 15px;
`;

export default Button;
